$(document).ready(function (){

    $('#participantStatistics').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [3],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 5,
        lengthMenu: [[5, 10, 25, -1], [5, 10, 25, "All"]]
    });


    $('#pendingRegistration').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [12],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#confirmedRegistration').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [13],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#paidRegistration').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
            targets  : [12],
            orderable: false,
        }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#deletedRegistration').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [13],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#pendingQuestion').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4, 5],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#answeredQuestion').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4, 7],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#pendingPayment').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [5],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#confirmedPayment').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [6],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#deletedPayment').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [5],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#participant').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [3],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#unsubmittedParticipant').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#unpaidInstitution').DataTable({
        processing: true,
        order: [],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#unregisteredInstitution').DataTable({
        processing: true,
        order: [],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#deletedParticipant').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [2],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#specialInvoice').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [5],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#incomingFollowUp').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#pendingFollowUp').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [5],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });


    $('#doneFollowUp').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [5],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#visibleMedPar').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [2],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#hiddenMedPar').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [2],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#visibleSponsor').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [2],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#hiddenSponsor').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [2],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#webinarPaymentPending').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#webinarPaymentConfirmed').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#webinarParticipantPending').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#webinarParticipantConfirmed').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [4],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#validUniversity').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
            targets  : [9],
            orderable: false,
        }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#invalidUniversity').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
            targets  : [9],
            orderable: false,
        }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#validHighSchool').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [9],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#invalidHighSchool').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [9],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#invalidParticipant').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [9],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });

    $('#invalidParticipant').DataTable({
        processing: true,
        order: [],
        columnDefs: [{
                        targets  : [9],
                        orderable: false,
                    }],
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6 pl-0 pr-1'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-12 col-md-5 pr-0 my-1'i><'col-sm-12 col-md-7 pl-0 my-1'p>>",
        pageLength: 10,
        lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]]
    });
});