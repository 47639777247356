$(window).on('load', function(){

        new WOW().init();
});

$(window).on('beforeunload', function () {
    $(".btnSubmit").prop('disabled', true);
});

$(document).ready(function () {

    $(function(){
        new WOW().init();
    });

    $('.custom-file input').change(function (e) {
        $(this).next('.custom-file-label').html(e.target.files[0].name);
    });

        if($(window).width() < 1200) {
            var trigger = $('.page-content');

            trigger.click(function(){
                $("#toggled").removeClass("toggled");
            });
        }
        else {
            $("#toggled").addClass("toggled");
        }
    

    $("#close-sidebar").click(function() {
        $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function() {
        $(".page-wrapper").addClass("toggled");
    });

    $(".sidebar-dropdown > a").click(function() {
        $(".sidebar-submenu").slideUp(200);
        if (
            $(this)
                .parent()
                .hasClass("active")
        ) {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
                .parent()
                .removeClass("active");
        } else {
            $(".sidebar-dropdown").removeClass("active");
            $(this)
                .next(".sidebar-submenu")
                .slideDown(200);
            $(this)
                .parent()
                .addClass("active");
        }
    });
});

// HEADLINE
$(document).ready(function() {
  var owl_headline = $(".headline-carousel");
  owl_headline.owlCarousel({
      autoplay: true,
      center: true,
      loop: true,
      dots: true,
      lazyLoad: true,
      items: 1,
      margin: 23,
      responsive: {
          0: {
              items: 1,
          },
          552: {
              items: 1,
          },
          768: {
              items: 2,
          },
          992: {
              items: 2,
          },
          1200: {
              loop: true,
              dots: true,
              stagePadding: 0,
              items: 2,
          }
    }
  });

  var owl_support = $(".support-carousel");
  owl_support.owlCarousel({
      autoplay: true,
      center: true,
      loop: true,
      dots: true,
      lazyLoad: true,
      nav: false,
      items: 6,
      margin: 23,
      responsive: {
          0: {
              items: 4,
          },
          552: {
              items: 4,
          },
          768: {
              items: 4,
          },
          992: {
              items: 5,
          },
          1200: {
              loop: true,
              dots: true,
              stagePadding: 0,
              items: 6,
          }
    }
  });

  var owl_competition = $(".competition-carousel");
  owl_competition.owlCarousel({
      autoplay: true,
      center: true,
      loop: true,
      dots: true,
      lazyLoad: true,
      nav: false,
      items: 5,
      margin: 23,
      responsive: {
          0: {
              items: 2,
          },
          552: {
              items: 3,
          },
          768: {
              items: 4,
          },
          992: {
              items: 4,
          },
          1200: {
              loop: true,
              dots: true,
              stagePadding: 0,
              items: 5,
          }
    }
 });
});

// PRELOADER
setTimeout(function(){
    $('.preloader').fadeToggle();
},800);